import React from 'react'
import { Text, Image, Keyboard, View } from 'react-native'
import { connect } from 'react-redux'
import Spinner from 'react-native-loading-spinner-overlay'
import { useForm } from 'react-hook-form'

// Actions
import PatientActions from 'APP/Redux/PatientRedux'

// Components
import ControllerInput from 'APP/Components/ControllerFields/ControllerInput'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import DialogueBranding from 'APP/Components/DialogueBranding'

// Styling
import Styles from './style'
import { Images } from 'APP/Themes'

// Utils
import Regex from 'APP/Lib/Regex'

// Services
import I18n from 'APP/Services/i18n'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import Button from 'APP/Converse/Button'

const ChangeEmailScreen = ({ changeEmailRunning, saveEmail }) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: null,
    },
  })

  const onSubmit = (formData) => {
    const email = formData.email?.toLowerCase().trim()
    saveEmail(email)
  }

  return (
    <NoticeBarContainer>
      <Spinner visible={changeEmailRunning} cancelable={false} />
      <ResponsiveScrollView alwaysBounceVertical={false}>
        <Image
          resizeMode={'contain'}
          style={Styles.illustration}
          source={Images.changeEmailIllustration}
        />
        <View style={Styles.buttonContainer}>
          <Text style={Styles.body}>{I18n.t('ChangeEmailScreen.body')}</Text>
          <ControllerInput
            name="email"
            rules={{
              required: I18n.t('ChangeEmailScreen.error.invalidEmail'),
              pattern: {
                value: Regex.email,
                message: I18n.t('ChangeEmailScreen.error.invalidEmail'),
              },
            }}
            testID="changeEmailFormInput"
            control={control}
            label={I18n.t('ChangeEmailScreen.placeholder')}
            keyboardType="email-address"
          />

          <Button
            testID="SAVE_BTN"
            title={I18n.t('ChangeEmailScreen.button')}
            onPress={() => {
              Keyboard.dismiss()
              handleSubmit(onSubmit)()
            }}
            widthVariant="full"
            analyticsName="ChangeEmailScreen.button"
            disabled={!isDirty || !isValid}
          />
        </View>
      </ResponsiveScrollView>
      <DialogueBranding />
    </NoticeBarContainer>
  )
}

const mapStateToProps = (state, props) => ({
  ...props.route?.params,
  changeEmailRunning: state.patient.changeEmailRunning,
})

const mapDispatchToProps = (dispatch) => ({
  saveEmail: (email) => dispatch(PatientActions.patientEmailChangeRequest(email)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmailScreen)
