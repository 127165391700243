import React, { useContext, useEffect, useState, memo, ReactNode } from 'react'
import { View } from 'react-native'
import { Appearance } from 'react-native-appearance'
import * as Sentry from '@sentry/react-native'

import { useSelector } from 'react-redux'
import { PostContext } from 'APP/Lib/Context'
import { getContentType } from 'APP/Lib/ContentHelpers'
import ContentCard from 'APP/Converse/ContentCard'
import TextMessage from 'APP/Components/FelixChat/TextMessage'
import ContentIcon from 'APP/Components/ContentIcon'
import { navigationRef as Nav } from 'APP/Nav'
import I18n from 'APP/Services/i18n'
import { getCmsContent } from 'APP/Services/CMS'
import Config from 'APP/Config'
import { ContentNoticeType } from 'APP/Converse/ContentCard/ContentCardNotice'
import { logDdError } from 'APP/Lib/Datadog'

interface Content {
  icon?: ReactNode
  imageUrl?: string
  length?: string
  title?: string
  loading: boolean
  notice?: { name: string; type: ContentNoticeType }
}

const MAX_WIDTH = 300

const CmsContent = () => {
  // While BM sends the CMS content ID according to the user's preferred language,
  // the locale is still needed for CMS articles.
  const profile = useSelector((state) => state.patient?.profile)
  const locale = profile?.preferred_language || 'en'

  const { post } = useContext(PostContext)
  const link = post?.props?.choices[0]?.link

  const [content, setContent] = useState<Content>({loading: true})

  const style = Appearance.getColorScheme()

  const onPress = () => {
    Nav.navigate('cmsScreen', {
      uri: `${Config.CMS.baseUrl}${link}?style=${style}&locale=${locale}`
    })
  }

  useEffect(() => {
    if (link) {
      (async function() {
        try {
          const fetchedData = await getCmsContent(link, style, true, locale)

          setContent({
            icon: <ContentIcon type={getContentType(fetchedData)} />,
            imageUrl: fetchedData?.thumbnail?.url,
            length: fetchedData?.length,
            title: fetchedData?.title,
            notice: fetchedData?.notice,
            loading: false
          })
        } catch (error) {
          Sentry.captureException(error)
          logDdError(error.message, error.stack)
          console.error(error)
        }
      })();
    }
  }, [link])

  return (
    <View testID="cms-content-view">
      <TextMessage />
      {!content.loading && (
        <ContentCard
          testID='cms-content-card-link'
          title={content?.title}
          onPress={onPress}
          icon={content?.icon}
          info={`${content?.length} ${I18n.t('Common.minutes')}`}
          imageUrl={content?.imageUrl}
          notice={content?.notice}
          maxWidth={MAX_WIDTH}
        />
      )}
    </View>
  )
}

export default memo(CmsContent)
