import React, { useState, useRef, useCallback, useEffect } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import PatientActions from 'APP/Redux/PatientRedux'
// Services
import I18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'

// Libs
import { getEnrolmentCodeErrorText } from 'APP/Lib/AuthHelpers'

// Components
import SignupLogoHeader from 'APP/Components/SignupLogoHeader'
import { ResponsiveView } from 'APP/Converse/Layout'

import Button from 'APP/Converse/Button'
import styled from 'styled-components/native'

import TextInput from 'APP/Converse/Inputs/TextInput'
import { useDevTools } from 'APP/Lib/DevTools'

const Page = styled(ResponsiveView)`
  flex: 1;
  justify-content: space-between;
  padding: ${({
    theme: {
      metrics: { baseMargin },
    },
  }) => `0 ${baseMargin}px ${baseMargin}px`};
`

const ClaimAccessScreen = ({ route }) => {
  const { code, analyticsName } = route?.params || {}
  const dispatch = useDispatch()
  const { error, requestRunning } = useSelector((state) => state.patient)
  const [enrolmentCode, setEnrolmentCode] = useState(code ?? '')
  const [showEnrolmentCodeError, setShowEnrolmentCodeError] = useState(false)
  const { setToolName, setToolFunction } = useDevTools()

  const [showAuthError, setShowAuthError] = useState(false)

  useEffect(() => {
    setToolName('Enrol')
    setToolFunction(() => () => {
      dispatch(PatientActions.patientEnrolWithEnrolmentCodesRequest(['bobettes']))
    })
    return () => {
      setToolFunction(null)
      setToolName(null)
    }
  }, [])

  const enrolmentCodeErrorText = getEnrolmentCodeErrorText(
    showEnrolmentCodeError,
    showAuthError,
    error
  )

  const enrolmentCodeRef = useRef(null)

  const handleChange = useCallback((text) => {
    setEnrolmentCode(text)
  }, [])

  const handleBlur = useCallback(() => {
    setShowAuthError(false)
    const isValid = enrolmentCode.length > 0
    if (!isValid) {
      setShowEnrolmentCodeError(true)
    } else {
      setShowEnrolmentCodeError(false)
    }
  }, [enrolmentCode])

  const isFormValid = requestRunning || !enrolmentCodeErrorText || enrolmentCode.length > 0

  const handleSubmit = useCallback(() => {
    Analytics.trackEvent(
      'button_click',
      {
        button_text: 'Next',
        button_value: 'Submit',
      },
      ['banner_context']
    )

    setShowEnrolmentCodeError(false)
    setShowAuthError(true)

    if (isFormValid) {
      dispatch(PatientActions.patientEnrolWithEnrolmentCodesRequest([enrolmentCode.trim()]))
    }
  }, [enrolmentCode, isFormValid, dispatch])

  return (
    <Page>
      <View>
        <SignupLogoHeader
          title={I18n.t('ClaimAccessScreen.title')}
          subtitle={I18n.t('ClaimAccessScreen.subtitle')}
        />
        <TextInput
          keyboardType="default"
          ref={enrolmentCodeRef}
          testID="ACCESS_CODE_FIELD"
          onChangeText={handleChange}
          onBlur={handleBlur}
          returnKeyType="next"
          onSubmitEditing={handleSubmit}
          autoCorrect={false}
          label={I18n.t('ClaimAccessScreen.accessCode')}
          value={enrolmentCode}
          error={enrolmentCodeErrorText || undefined}
        />
      </View>
      <Button
        widthVariant="full"
        title={I18n.t('ClaimAccessScreen.next') || ''}
        analyticsName={`${analyticsName}.next`}
        disabled={!isFormValid || requestRunning}
        onPress={handleSubmit}
        isLoading={requestRunning}
        testID="NEXT_BTN"
      />
    </Page>
  )
}

export default ClaimAccessScreen
