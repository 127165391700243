import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WellnessCenter } from '@dialogue/services'
import WellnessCenterActions from 'APP/Redux/WellnessCenterRedux'
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import sprigClient from 'APP/Services/Sprig'
import Animation from 'APP/Components/Animation'
import { PrimaryButton } from 'APP/Components/Buttons'

const { Challenges } = WellnessCenter

const ANALYTICS = {
  JOIN_CHALLENGE: 'WELLNESS_CENTER_JOINED_CHALLENGE',
}

const JoinButton = ({ setVisibility, toggleTeamModal, ...props }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [retry, setRetry] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)

  const { challenge, participants, inChallenge, joining, ...selectedChallenge } = useSelector(
    (state) => state.wellnessCenter.selectedChallenge
  )

  const isTeam = challenge.type === Challenges.Types.ChallengeType.TEAM

  useEffect(() => {
    if (clicked && !joining) {
      // successfully joined
      if (inChallenge) {
        setLoading(false)
        setShowConfetti(true)

        // Hide button after confetti has completed
        setTimeout(() => {
          if (setVisibility) setVisibility(false)
        }, 1500)
      }

      // failed to join
      if (selectedChallenge.retry) {
        setLoading(false)
        setClicked(false)
        setRetry(true)
      }
    }
  }, [clicked, inChallenge, joining, selectedChallenge.retry, participants])

  const joinChallenge = () => {
    Analytics.trackEvent('button_click', {
      button_value: 'Join challenge',
      trigger: `Join challenge id: ${challenge.id}`,
    })
    sprigClient.track(ANALYTICS.JOIN_CHALLENGE)

    if (isTeam) {
      toggleTeamModal(true)
    } else {
      setClicked(true)
      setLoading(true)
      dispatch(WellnessCenterActions.joinIndividualChallenge(challenge.id))
    }
  }

  if (showConfetti) {
    return (
      <Animation text="🔥" numberOfElems={25}>
        <Animation text="💯" numberOfElems={20}>
          <PrimaryButton
            {...props}
            title={I18n.t('WellnessCenter.challenges.successJoin')}
            showActivityIndicator={false}
            disabled={false}
            textID="CELEBRATE_JOIN_CHALLENGE_BTN"
          />
        </Animation>
      </Animation>
    )
  }

  return (
    <PrimaryButton
      {...props}
      title={
        retry
          ? I18n.t('WellnessCenter.challenges.retryJoin')
          : I18n.t('WellnessCenter.challenges.join')
      }
      showActivityIndicator={loading}
      disabled={loading || joining}
      onPress={joinChallenge}
      testID="JOIN_CHALLENGE_BTN"
    />
  )
}

export default JoinButton
