import React from 'react'
import { Linking, View, Image, TouchableOpacity, Modal, ScrollView } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import I18n from 'APP/Services/i18n'
import Typography from 'APP/Converse/Typography'
import DialogueMarkdown from 'APP/Components/DialogueMarkdown'
import { PrimaryButton } from 'APP/Components/Buttons'
import { useTerminateModal } from 'APP/Hooks/TerminateModal'
import { Colors, Metrics, Images } from 'APP/Themes'
import UrlBuilder from 'APP/Services/UrlBuilder'
import Styles, { modalTrackerMarkdownContent, modalTrackerMarkdownAlert } from './style'

const ProviderIntroModal = React.forwardRef(({ provider, onConnect, onClose }, ref) => {
  const isVisible = !!provider

  const shouldDisplayMarkdown = provider
    ? I18n.exists(`HealthTrackerSettings.modal.${provider}.markdown`)
    : false
  const shouldDisplayAlert = provider
    ? I18n.exists(`HealthTrackerSettings.modal.${provider}.alert`)
    : false

  const modalProps = useTerminateModal(ref, isVisible, onClose)

  const goToPrivacyPolicy = () => Linking.openURL(UrlBuilder.privacyUrl())
  const legalDisclaimer = I18n.t('HealthTrackerSettings.modal.trackerDisclaimer', {
    returnObjects: true,
  })

  return (
    <Modal
      statusBarTranslucent
      transparent
      animationType="slide"
      visible={isVisible}
      testID="providerIntroModal"
      {...modalProps}
    >
      {!!provider && (
        <View style={Styles.modalContainer}>
          <View style={Styles.modal}>
            <View style={Styles.modalHeader}>
              <TouchableOpacity
                onPress={onClose}
                testID="closeProviderIntroModal"
                style={Styles.modalHeaderClose}
              >
                <MaterialIcon name="close" size={Metrics.icons.small} color={Colors.text} />
              </TouchableOpacity>
              <Typography variant="h2">
                {I18n.t(`HealthTrackerSettings.modal.${provider}.title`)}
              </Typography>
            </View>
            <ScrollView
              showsVerticalScrollIndicator={false}
              contentContainerStyle={Styles.modalContent}
              testID="modalListContainer"
            >
              <View style={Styles.modalTitleContainer}>
                <Image style={Styles.modalTitleImage} source={Images.trackers?.[provider]} />
                <Typography variant="h3">
                  {I18n.t(`HealthTrackerSettings.trackerProviders.${provider}`)}
                </Typography>
              </View>
              <Typography variant="h4" style={Styles.modalSubtitle}>
                {I18n.t(`HealthTrackerSettings.modal.${provider}.subtitle`)}
              </Typography>
              {shouldDisplayAlert && (
                <View style={Styles.modalAlert}>
                  <MaterialIcon
                    name="info-outline"
                    style={Styles.modalAlertIcon}
                    size={24}
                    color={Colors.text}
                  />
                  <DialogueMarkdown
                    markdown={I18n.t(`HealthTrackerSettings.modal.${provider}.alert`)}
                    styles={modalTrackerMarkdownAlert}
                  />
                </View>
              )}
              {shouldDisplayMarkdown && (
                <DialogueMarkdown
                  markdown={I18n.t(`HealthTrackerSettings.modal.${provider}.markdown`)}
                  styles={modalTrackerMarkdownContent}
                />
              )}
              <PrimaryButton
                onPress={() => onConnect(provider)}
                title={I18n.t(`HealthTrackerSettings.modal.${provider}.connectCta`)}
                testID="TRACKER_CONNECT_CTA_BTN"
                style={Styles.modalTrackerCta}
              />
              <Typography variant="bodySmall" color="dim" style={Styles.disclaimer}>
                {legalDisclaimer[0]}
                <Typography
                  variant="bodySmall"
                  color="accent"
                  style={Styles.disclaimerLink}
                  onPress={goToPrivacyPolicy}
                  testID="trackerDisclaimerLink"
                >
                  {legalDisclaimer[1]}
                </Typography>
              </Typography>
            </ScrollView>
          </View>
        </View>
      )}
    </Modal>
  )
})
ProviderIntroModal.displayName = 'ProviderIntroModal'

export default ProviderIntroModal
