import React, { useRef, useState, useEffect } from 'react'
import { FlatList, TouchableOpacity, Dimensions } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import Typography from 'APP/Converse/Typography'

import ContentCard from 'APP/Converse/ContentCard'
import ServiceCard from 'APP/Converse/ServiceCard'
import { navigateToCms, normalizeServiceV2, getContentType } from 'APP/Lib/ContentHelpers'
import useHandleOnBenefitsPress from 'APP/Hooks/BenefitNavigationCTA'
import { CONTENT_FILTERS } from 'APP/Hooks/ContentLibrary'
import I18n from 'APP/Services/i18n'
import ContentIcon from 'APP/Components/ContentIcon'

import { Colors } from 'APP/Themes'

import Styles, { MAX_WIDTH, MAX_SERVICE_WIDTH, StyledHeaderContainer } from './style'

const calcServiceWidth = () => {
  const width = Dimensions.get('screen').width
  return Math.min(MAX_SERVICE_WIDTH, width)
}

const isService = (item) =>
  [
    CONTENT_FILTERS.APP_EXTERNAL_SERVICE,
    CONTENT_FILTERS.APP_INTERNAL_SERVICE,
    CONTENT_FILTERS.EMPLOYEE_BENEFIT,
  ].includes(item.content_type)
const CMSContentList = ({ title, data, onPressBeforeNav, analyticsScreenName }) => {
  const flatListRef = useRef()
  const [index, setIndex] = useState(0)

  const leftScrollDisabled = index == 0
  const rightScrollDisabled = index == data.length - 1
  const serviceWidth = calcServiceWidth()

  useEffect(() => {
    flatListRef.current.scrollToIndex({
      index: index,
      animated: true,
    })
  }, [index])

  const scrollLeft = () => {
    setIndex(Math.max(0, index - 1))
  }
  const scrollRight = () => {
    setIndex(Math.min(data.length - 1, index + 1))
  }

  const handleOnPress = async (item, analyticsName) => {
    if (typeof onPressBeforeNav === 'function') {
      // is used to perform an action before navigating to the next screen
      await onPressBeforeNav()
    }
    navigateToCms(item, analyticsName, 'wcArticleScreen', true)
  }
  const handleOnBenefitsPress = useHandleOnBenefitsPress()

  const renderItem = ({ item, index }) => {
    const multipleItems = data.length > 1
    const cardWidth = multipleItems ? serviceWidth * 0.9 : serviceWidth
    if (isService(item)) {
      const normalizedService = normalizeServiceV2(item)
      return (
        <ServiceCard
          service={normalizedService}
          onPress={() => handleOnBenefitsPress(item, analyticsScreenName)}
          testID={'list-item'}
          maxWidth={cardWidth}
        />
      )
    } else {
      return (
        <ContentCard
          title={item.title}
          imageUrl={item.thumbnail?.url}
          icon={<ContentIcon type={getContentType(item)} />}
          info={`${item.length} ${I18n.t('WellnessCenter.minute')}`}
          onPress={() => handleOnPress(item, index)}
          tag={item.difficulty}
          notice={item?.notice}
          maxWidth={MAX_WIDTH}
          testID={'list-item'}
        />
      )
    }
  }

  return (
    <>
      <StyledHeaderContainer>
        <Typography testID={title} variant="h2" style={Styles.sectionTitle}>
          {title}
        </Typography>
        <TouchableOpacity
          disabled={leftScrollDisabled}
          onPress={scrollLeft}
          style={[Styles.scrollIcon, Styles.scrollIconLeft]}
          testID="scrollLeft"
        >
          <MaterialIcon
            name="chevron-left"
            size={24}
            color={leftScrollDisabled ? Colors.textDisabled : Colors.text}
          />
        </TouchableOpacity>
        <TouchableOpacity
          disabled={rightScrollDisabled}
          onPress={scrollRight}
          style={[Styles.scrollIcon, Styles.scrollIconRight]}
          testID="scrollRight"
        >
          <MaterialIcon
            name="chevron-right"
            size={24}
            color={rightScrollDisabled ? Colors.textDisabled : Colors.text}
          />
        </TouchableOpacity>
      </StyledHeaderContainer>
      <FlatList
        ref={flatListRef}
        data={data}
        horizontal
        showsHorizontalScrollIndicator={false}
        testID="contentList"
        removeClippedSubviews={true}
        initialNumToRender={3}
        style={Styles.list}
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderItem}
        onScrollToIndexFailed={async () => {
          await new Promise((resolve) => setTimeout(resolve, 500))
          flatListRef.current?.scrollToIndex({ index: 0, animated: true })
        }}
      />
    </>
  )
}

export default CMSContentList
