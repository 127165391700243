import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActivityIndicator, View } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import { useFocusEffect, useNavigation } from '@react-navigation/native'

import ActiveMinutesActions from 'APP/Redux/ActiveMinutesRedux'
import Analytics, { SCROLL_BEHAVIOUR_ACTIONS } from 'APP/Services/Analytics'
import ActiveMinutes from 'APP/Containers/ActiveMinutes'

import Typography from 'APP/Converse/Typography'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import Challenges_legacy from 'APP/Containers/WellnessCenterChallengesScreen_legacy'
import withFeatureEnabled from 'APP/Helpers/withFeatureEnabled'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import { PrimaryTextButton } from 'APP/Components/Buttons'

import I18n from 'APP/Services/i18n'

import { Colors, Metrics } from 'APP/Themes'
import Styles from './style'
import { CARD_SIZE, ChallengeCard } from 'APP/Components/Challenge'

const ChallengeSection = ({
  loading,
  retry,
  onRetry,
  data,
  row,
  emptyTitle,
  emptyCopy,
  analyticsName,
}) => {
  const navigation = useNavigation()

  const navigateToChallenge = (id) => {
    navigation.navigate('wcChallenge', { id })
  }

  return (
    <View style={Styles.section}>
      {loading && !data && (
        <ActivityIndicator
          size="large"
          color={Colors.text}
          style={Styles.loading}
          testID="loading"
        />
      )}
      {retry && (
        <DialogueTouchableOpacity
          style={Styles.refresh}
          onPress={onRetry}
          analyticsName="Refresh"
          testID="retry"
        >
          <MaterialIcon
            name="refresh"
            size={Metrics.icons.large}
            color={Colors.text}
            style={Styles.refreshIcon}
          />
        </DialogueTouchableOpacity>
      )}
      {!retry && data?.length === 0 && (
        <View style={Styles.empty} testID="empty-view">
          <Typography
            variant="subheaderBold"
            align="center"
            style={Styles.emptyTitle}
            testID="empty-title"
          >
            {emptyTitle}
          </Typography>
          <Typography variant="bodyNormal" align="center" testID="empty-copy">
            {emptyCopy}
          </Typography>
        </View>
      )}
      {!retry && data?.length > 0 && (
        <View style={row && Styles.row} testID="challenge-list">
          {data.map((challenge, i) => (
            <ChallengeCard
              key={challenge.id}
              size={row ? CARD_SIZE.SMALL : CARD_SIZE.LARGE}
              challenge={challenge}
              onPress={() => navigateToChallenge(challenge.id)}
              analyticsName={`${analyticsName}.${i}`}
            />
          ))}
        </View>
      )}
    </View>
  )
}

const Challenges = () => {
  const { eligibleServices } = useSelector((state) => state.patient.profile)
  const features = useSelector((state) => state.features)
  const navigation = useNavigation()
  const showWeeklyActiveMinutes = features?.weeklyActiveMinutes || false
  const [trackScrollProps, setTrackScrollProps] = useState(null)
  const dispatch = useDispatch()
  const getChallenges = () => dispatch(ActiveMinutesActions.getChallenges())
  const { retry, loading, joined, newAndUpcoming } = useSelector(
    (state) => state.activeMinutes.challenges
  )

  // When layout is ready set up the onScroll handler
  const onActiveMinutesLayout = useCallback((event) => {
    const { height } = event.nativeEvent.layout
    setTrackScrollProps({
      // iOS requires us to specify the interval in ms with which
      // the scroll event will be fired. 16 is the biggest most responsive interval
      // https://reactnative.dev/docs/scrollview#scrolleventthrottle-ios
      scrollEventThrottle: 16,
      onScroll: (event) => {
        if (event.nativeEvent.contentOffset.y > height * 0.5) {
          Analytics.trackScrollingBehaviour({
            label: 'weekly active minutes',
            action: SCROLL_BEHAVIOUR_ACTIONS.SCROLL_OUT_OF_VIEW,
          })
          setTrackScrollProps(null)
        }
      },
    })
  }, [])

  useFocusEffect(
    useCallback(() => {
      getChallenges()
    }, [])
  )

  const commonSectionProps = {
    retry,
    loading,
    onRetry: getChallenges,
  }

  return (
    <ResponsiveScrollView {...trackScrollProps} testID="challenges">
      {showWeeklyActiveMinutes && <ActiveMinutes onLayout={onActiveMinutesLayout} />}
      {/* check for brands that have active minutes enabled (LD flag) hence the tab is shown
      but if they don't have challenges enabled explicitly - hide them */}
      {!!eligibleServices.wellness_challenges && (
        <>
          <View style={Styles.sectionHeader} testID="challenge_list_header">
            <Typography variant="h3">{I18n.t('WellnessCenter.challenges.sections.my')}</Typography>
            <PrimaryTextButton
              title={I18n.t('WellnessCenter.challenges.sections.completed')}
              containerStyle={Styles.sectionHeaderButton}
              onPress={() => navigation.navigate('wcCompletedChallenges')}
              testID="COMPLETED_CHALLENGES_BTN"
            />
          </View>
          <ChallengeSection
            data={joined}
            emptyTitle={I18n.t('WellnessCenter.challenges.emptyList.my.title')}
            emptyCopy={I18n.t('WellnessCenter.challenges.emptyList.my.copy')}
            analyticsName="myChallenges.card"
            {...commonSectionProps}
          />
          <View style={Styles.sectionHeader}>
            <Typography variant="h3">{I18n.t('WellnessCenter.challenges.sections.new')}</Typography>
          </View>
          <ChallengeSection
            row
            data={newAndUpcoming}
            emptyTitle={I18n.t('WellnessCenter.challenges.emptyList.upcoming.title')}
            emptyCopy={I18n.t('WellnessCenter.challenges.emptyList.upcoming.copy')}
            analyticsName="newChallenges.card"
            {...commonSectionProps}
          />
        </>
      )}
    </ResponsiveScrollView>
  )
}

export default withFeatureEnabled({
  memoizationKey: 'Challenges',
  enabled: Challenges,
  default: Challenges_legacy,
  featureKey: 'terraDrivenWellness',
})
