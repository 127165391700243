import React, { useEffect, useState, useCallback } from 'react'
import { FlatList, Text, View, ActivityIndicator } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { navigationRef as Nav } from 'APP/Nav'

import { contentActions } from 'APP/Store/Content'
import { featuresState } from 'APP/Sagas/FeaturesSaga'

import { PrimaryButton } from 'APP/Components/Buttons'
import Button from 'APP/Converse/Button'
import Toggles from 'APP/Components/Toggles'
import CMSContentList from 'APP/Components/CMSContentList'
import Filters from 'APP/Components/ContentFilter'
import {
  INITIAL_LANGUAGE_FILTERS,
  INITIAL_LIBRARY_FILTERS,
  useLibraryFilters,
} from 'APP/Hooks/ContentLibrary'
import { shouldRenderComponent } from 'APP/Lib/ContentHelpers'
import I18n from 'APP/Services/i18n'
import Retry from 'APP/Images/retry.svg'

import Styles from './style'
import { Colors, Metrics } from 'APP/Themes'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import { HelpButtonComponent } from 'APP/Components/HelpButtonComponent'

const getFilteredToggles = (categories, eligibleServices) => {
  let filteredCategories = []
  if (categories) {
    categories.forEach((category) => {
      const filteredCategory = {
        id: category.id,
        name: category.name,
        title: category.title,
        hasContent: category.content_subcategories.some((subcategory) => {
          return subcategory.data.some((item) =>
            shouldRenderComponent(item.service_filter, eligibleServices)
          )
        }),
      }
      filteredCategories.push(filteredCategory)
    })
  }
  return filteredCategories
}

const CmsFeedScreen = ({ contentId }) => {
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const [filtersVisible, setFiltersVisible] = useState(false)
  const { cmsFeed } = useSelector((state) => state.content)
  const { eligibleServices, healthResources } = useSelector((state) => state.patient.profile)
  const { useBenefitsNavigationBrowse } = useSelector(featuresState)

  const [index, setIndex] = useState()
  const data = cmsFeed?.data?.[contentId]
  const error = cmsFeed?.error

  const contentCategories = data?.content_categories
  const isContentReady = !cmsFeed?.loading && contentCategories

  const library =
    isContentReady && index
      ? contentCategories?.[index].content_subcategories
      : contentCategories?.[0].content_subcategories

  const toggles = getFilteredToggles(contentCategories, eligibleServices)

  const languageFilters = useLibraryFilters({
    baseOptions: INITIAL_LANGUAGE_FILTERS,
    library,
    eligibleServices,
    healthResources,
    useBenefitsNavigationBrowse,
    persisted: true,
  })
  const currentLocale = languageFilters?.options?.[0]?.checked?.['0']

  const refetch = useCallback(() => {
    dispatch(contentActions.getCmsFeed({ contentId, locale: currentLocale }))
  }, [contentId, currentLocale])

  useEffect(() => {
    dispatch(contentActions.getCmsFeed({ contentId, locale: currentLocale }))
  }, [contentId, currentLocale])

  // set library to user-selected category
  const handleToggle = (index) => {
    setIndex(index)
  }

  const libraryFilters = useLibraryFilters({
    baseOptions: INITIAL_LIBRARY_FILTERS,
    library: languageFilters.updatedLibrary,
    eligibleServices,
    healthResources,
    useBenefitsNavigationBrowse,
    persisted: false,
  })

  useEffect(() => {
    if (!cmsFeed?.loading) {
      navigation.setOptions({
        headerRight: () => (
          <>
            <Button
              testID="CMS_FILTER_BTN"
              variant="tertiary"
              icon="tune-vertical-variant"
              iconVariant="material"
              onPress={() => setFiltersVisible(true)}
              widthVariant="content"
              style={Styles.filterBtn}
            />
            {libraryFilters.appliedFiltersCount > 0 && (
              <View testID="filter-badge" style={Styles.filterDot} />
            )}
          </>
        ),
      })
    }

    return () => {
      const isMindToolkitsTab = Nav.getCurrentRoute()?.params?.tabName?.includes('Toolkits')

      navigation.setOptions({
        headerRight: isMindToolkitsTab ? () => <HelpButtonComponent /> : null,
      })
    }
  }, [cmsFeed, navigation, libraryFilters])

  if (error) {
    return (
      <View style={Styles.retry} testID="retry">
        <Retry fillLine={Colors.text} width={Metrics.icons.large} height={Metrics.icons.large} />
        <Text style={Styles.retryTitle}>{I18n.t('CmsFeedScreen.retry.title')}</Text>
        <Text style={Styles.retryCopy}>{I18n.t('CmsFeedScreen.retry.copy')}</Text>
        <PrimaryButton
          style={Styles.retryCta}
          title={I18n.t('CmsFeedScreen.retry.cta')}
          onPress={refetch}
        />
      </View>
    )
  }

  return (
    <View testID="library" style={Styles.container}>
      <ResponsiveScrollView>
        {isContentReady && toggles?.length > 1 && (
          <Toggles testID="toggles" toggles={toggles} onChange={handleToggle} />
        )}
        <View style={cmsFeed?.loading ? Styles.noContent : Styles.list}>
          {!isContentReady && (
            <ActivityIndicator
              size="large"
              color={Colors.text}
              style={Styles.loading}
              testID="loading"
            />
          )}
          {isContentReady && libraryFilters.updatedLibrary && (
            <View testID="library-list">
              <FlatList
                testID="category-section"
                renderItem={({ item }) =>
                  item?.data?.length > 0 ? (
                    <CMSContentList
                      key={item.name}
                      title={item.title}
                      data={item.data}
                      analyticsScreenName={'browseScreen'}
                    />
                  ) : null
                }
                data={libraryFilters.updatedLibrary}
              />
            </View>
          )}
        </View>
      </ResponsiveScrollView>
      {isContentReady && (
        <Filters
          visible={filtersVisible}
          languageFilters={languageFilters}
          libraryFilters={libraryFilters}
          onClose={() => setFiltersVisible(false)}
          fetching={cmsFeed.processing}
        />
      )}
    </View>
  )
}

export default CmsFeedScreen
