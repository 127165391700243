import snakeCaseKeys from 'snakecase-keys'
import { omit } from 'ramda'
import { isWeb } from 'APP/Helpers/checkPlatform'

export function transformAnswer(place) {
  if (isWeb()) {
    const formatedPlace = {
      ...place,
      address: place.formatted_address,
      location: {
        latitude: place.geometry?.location?.lat(),
        longitude: place.geometry?.location?.lng(),
      },
    }
    delete formatedPlace.placeId
    return formatedPlace
  }

  const mappedAddressComponents = []
  place.addressComponents.forEach((addressComponent) => {
    const mappedComponent = {
      ...addressComponent,
      longName: addressComponent.name,
    }
    delete mappedComponent.name
    mappedAddressComponents.push(mappedComponent)
  })

  return snakeCaseKeys(
    omit(['priceLevel'], { ...place, addressComponents: mappedAddressComponents })
  )
}

export function normalizeAddressPayload(payload) {
  const newPayload = {
    ...payload,
    addressComponents: payload.addressComponents?.map((component) => ({
      long_name: (isWeb() ? component.long_name : component.longName) || component.name,
      short_name: isWeb() ? component.short_name : component.shortName,
      types: component.types,
    })),
    formatted_phone_number: payload.phoneNumber,
    // `placeID` is otherwise snakecased to `place_i_d`
    placeId: payload.placeID,
  }
  delete newPayload.placeID

  const snakeCasePayload = snakeCaseKeys(newPayload)

  // We do JSON.stringify two times because Rasa needs a JSON string of a JSON object.
  return JSON.stringify(JSON.stringify(snakeCasePayload))
}

export function getSelectedAddressPayload(place, place_type, payload_template) {
  const addressInfo = {
    address: place.address,
    name: place.name,
    openingHours: place.openingHours,
    phoneNumber: place.phoneNumber,
    placeType: place_type,
    placeId: place.placeID,
  }

  const stringifiedPayload = normalizeAddressPayload(place)

  const selectedAddress = {
    payloadTemplate: payload_template,
    addressInfo: addressInfo,
    payload: stringifiedPayload,
  }

  return selectedAddress
}
