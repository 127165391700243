import React, { useState, useRef, useMemo, useEffect } from 'react'
import { View, Image, Text, Platform } from 'react-native'
import { ImageMapSelector } from '@dialogue/components'

// Components
import { PrimaryButton, SecondaryButton } from 'APP/Components/Buttons'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import ZoomHint from 'APP/Components/ZoomHint'
import GroupedChoicePicker from 'APP/Components/GroupedChoicePicker'
import Tabs, { TabContent } from 'APP/Components/Tabs'

// Services
import I18n from 'APP/Services/i18n'
import { isWeb } from 'APP/Helpers/checkPlatform'

// Styles
import { Images, Metrics } from 'APP/Themes'
import { Styles } from './style'

const ImageMapHint = ({ maxSelectionCount }) => {
  const [showHint, setShowHint] = useState(false)

  const toggleOpen = () => {
    setShowHint(!showHint)
  }

  return (
    <View style={{ position: 'absolute', top: 10, right: Metrics.baseMargin, zIndex: 10 }}>
      <View styles={{ position: 'relative' }}>
        <DialogueTouchableOpacity
          style={Styles.hintIconButton}
          onPress={toggleOpen}
          analyticsName="Image map more info"
        >
          <Image
            source={Images.questionMarkCircle}
            style={Styles.hintIcon}
            resizeMode={'contain'}
          />
        </DialogueTouchableOpacity>
        {showHint && (
          <View style={Styles.popoverWindow}>
            <View style={Styles.popoverTail} />
            <View style={Styles.popoverContent}>
              <Text style={Styles.popoverSubtitle}>
                {`${
                  maxSelectionCount > 0
                    ? I18n.t('ImageMap.hint.descriptionSelectionLimit', { maxSelectionCount })
                    : I18n.t('ImageMap.hint.descriptionNoSelectionLimit')
                } ${I18n.t('ImageMap.hint.description')}`}
              </Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

const ActionButtons = ({ onReset, onNextPress, submitDisabled }) => {
  return (
    <View style={Styles.footer}>
      <SecondaryButton
        style={Styles.button}
        onPress={onReset}
        analyticsName="IntakeComponent.reset"
        testID="RESET_BTN"
      >
        {I18n.t('IntakeComponent.reset')}
      </SecondaryButton>
      <PrimaryButton
        analyticsName="IntakeComponent.next"
        disabled={submitDisabled}
        style={Styles.button}
        onPress={onNextPress}
        testID="NEXT_BTN"
      >
        {I18n.t('IntakeComponent.next')}
      </PrimaryButton>
    </View>
  )
}

const denormalizeListMetadata = (group, choices) =>
  group?.map((child) => {
    if (child.children) {
      return {
        ...child,
        children: denormalizeListMetadata(child.children, choices),
      }
    } else if (child.choices) {
      return {
        ...child,
        choices: child.choices.map((id) => choices[id]),
      }
    }
    return child
  })

const ImageMapPicker = ({
  map_title,
  map_icon,
  list_title,
  list_icon,
  list_metadata,
  image_source,
  image_metadata,
  choices,
  minimum_selection: minSelection = 0,
  maximum_selection: maxSelection,
  disabled,
  sendAnswer,
  ready = true,
  // includeListView is only supported for WEB
  includeListView: includeListViewProp,
}) => {
  const includeListView = isWeb() && includeListViewProp
  const [activeTab, setActiveTab] = useState(0)
  const [selectedRegions, setSelectedRegions] = useState([])
  const [notices, setNotices] = useState([])

  const zoomRef = useRef()
  const regionsLength = selectedRegions.length
  const noMinSelection = minSelection === 0
  const noMaxSelection = maxSelection === undefined
  const isLimitReached = !noMaxSelection && regionsLength > maxSelection
  const submitDisabled =
    disabled || isLimitReached || (!noMinSelection && regionsLength < minSelection)

  const choiceMap = useMemo(() => {
    const map = {}
    choices?.forEach((choice) => (map[choice.id] = choice))
    return map
  }, [choices])
  const groups = useMemo(
    () => denormalizeListMetadata(list_metadata, choiceMap),
    [list_metadata, choiceMap]
  )

  const maxReachedNotices = useMemo(
    () => [
      {
        title: I18n.t('ImageMap.notice.maxReached.title', {
          maxSelectionCount: maxSelection,
        }),
        titleStyle: Styles.noticeTitleStyle,
      },
    ],
    [maxSelection]
  )

  useEffect(() => {
    setNotices(isLimitReached ? maxReachedNotices : [])
  }, [isLimitReached])

  const onChange = (incomingRegionIds) => {
    setSelectedRegions(incomingRegionIds)
  }

  const onListRegionPress = ({ id }) => {
    const nextRegions = [...selectedRegions]
    const indexOfRegion = nextRegions.indexOf(id)
    if (indexOfRegion === -1) {
      nextRegions.push(id)
    } else if (indexOfRegion !== -1) {
      nextRegions.splice(indexOfRegion, 1)
    }
    setSelectedRegions(nextRegions)
  }

  const setZoomRef = (node) => {
    if (node) {
      zoomRef.current = node
    }
  }

  const resetZoomScale = () => {
    if (zoomRef) {
      zoomRef.current?.reset?.()
    }
  }

  const onReset = () => {
    setSelectedRegions([])
    resetZoomScale()
  }

  const filterMetadata = (ids, metadata) => {
    if (!Array.isArray(ids) || typeof metadata !== 'object') {
      return metadata
    }

    let filteredMetadata = {}
    ids.forEach((region) => (filteredMetadata[region] = metadata[region]))

    return filteredMetadata
  }

  const onNextPress = () => {
    if (!submitDisabled) {
      sendAnswer(
        choices.filter((choice) => selectedRegions.includes(choice.id)),
        filterMetadata(selectedRegions, image_metadata)
      )
    }
  }

  const imageMapProps = {
    zoom: true,
    zoomRef: setZoomRef,
    maximumZoomScale: Platform.OS === 'android' ? 2 : 8,
    style: Styles.imagePicker,
    innerContainerStyle: Styles.imagePickerInnerContainer,
    imageScale: 0.9,
    imageSource: image_source,
    imageRegions: image_metadata,
    selectedRegions: selectedRegions,
    onChange: onChange,
  }

  return (
    <NoticeBarContainer
      top
      float={!includeListView}
      centerText
      containerStyle={Styles.container}
      style={Styles.notice}
      localNotices={notices}
    >
      {includeListView ? (
        <>
          <Tabs value={activeTab} onChange={setActiveTab} analyticsName="ImageMap.TapBar">
            <Tabs.Item title={map_title} icon={map_icon} />
            <Tabs.Item title={list_title} icon={list_icon} testID="listTab" />
          </Tabs>
          <TabContent value={activeTab}>
            <TabContent.Item index={0}>
              <ImageMapSelector {...imageMapProps} />
              <ImageMapHint maxSelectionCount={maxSelection} />
            </TabContent.Item>
            <TabContent.Item index={1}>
              <GroupedChoicePicker
                multi
                groups={groups}
                style={Styles.footerSpacing}
                selectedChoices={selectedRegions}
                onChoicePress={onListRegionPress}
                analyticsName="Intake.ImageMapPicker.List"
                testID="groupedChoicePicker"
              />
            </TabContent.Item>
          </TabContent>
        </>
      ) : (
        <ImageMapSelector {...imageMapProps} />
      )}
      <ActionButtons onReset={onReset} onNextPress={onNextPress} submitDisabled={submitDisabled} />
      {!includeListView && <ImageMapHint maxSelectionCount={maxSelection} />}
      <ZoomHint modal={false} ready={ready} style={Styles.zoomHintOverlay} />
    </NoticeBarContainer>
  )
}

export default ImageMapPicker
