import React, { useCallback, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View } from 'react-native'

import PatientActions from 'APP/Redux/PatientRedux'

import ChallengedInvitationFooter from 'APP/Components/ChallengedInvitationFooter'

import I18n from 'APP/Services/i18n'

import { isValidEmailAddress } from 'APP/Lib/Utilities'
import { ResponsiveView } from 'APP/Converse/Layout'
import SignupLogoHeader from 'APP/Components/SignupLogoHeader'
import styled from 'styled-components/native'
import Button from 'APP/Converse/Button'
import TextInput from 'APP/Converse/Inputs/TextInput'

const Page = styled(ResponsiveView)`
  flex: 1;
  justify-content: space-between;
  padding: ${({
    theme: {
      metrics: { baseMargin },
    },
  }) => `0 ${baseMargin}px ${baseMargin}px`};
`

const InvitationEmailScreen = ({ route }) => {
  const { analyticsName } = route?.params || {}
  const dispatch = useDispatch()
  const { requestRunning, challengedInvitationQueue } = useSelector((state) => state.patient)

  const [email, setEmail] = useState('')
  const [showEmailError, setShowEmailError] = useState(false)
  const ref = useRef(null)
  const isInChallengedQueue = (challengedInvitationQueue ?? []).length > 0
  const invitedEmailErrorText = showEmailError ? I18n.t('InvitationEmailScreen.invalidEmail') : ''

  const isFormValid = !requestRunning && isValidEmailAddress(email)

  const handleSubmit = () => {
    setShowEmailError(false)
    if (isFormValid) {
      dispatch(PatientActions.resendInvitationRequest(email))
    }
  }

  const handleBlur = useCallback(() => {
    const isInvalidEmail = !isValidEmailAddress(email)
    if (isInvalidEmail) {
      setShowEmailError(true)
    } else {
      setShowEmailError(false)
    }
  }, [email])

  return (
    <Page>
      <View>
        <SignupLogoHeader
          title={I18n.t('InvitationEmailScreen.title')}
          subtitle={I18n.t('InvitationEmailScreen.body')}
        />
        <TextInput
          keyboardType="email-address"
          autoFocus={true}
          ref={ref}
          testID="INVITATION_EMAIL_INPUT"
          onChangeText={setEmail}
          onBlur={handleBlur}
          returnKeyType="next"
          onSubmitEditing={handleSubmit}
          autoCorrect={false}
          label={I18n.t('InvitationEmailScreen.inputLabel')}
          value={email}
          error={invitedEmailErrorText}
        />
      </View>
      <View>
        <Button
          widthVariant="full"
          analyticsName="Submit"
          title={I18n.t('InvitationEmailScreen.next') || ''}
          disabled={!isFormValid || requestRunning}
          onPress={handleSubmit}
          isLoading={requestRunning}
          testID="NEXT_BTN"
        />
        {isInChallengedQueue ? <ChallengedInvitationFooter analyticsName={analyticsName} /> : null}
      </View>
    </Page>
  )
}

export default InvitationEmailScreen
