import React, { useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Linking, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Moment from 'moment-timezone'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import * as AddCalendarEvent from 'react-native-add-calendar-event'

import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import BottomSheetModal from 'APP/Components/BottomSheetModal'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import { PrimaryButton, SecondaryButton } from 'APP/Components/Buttons'
import Button from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'

import { createIcsFile } from 'APP/Helpers/IcsUtils'
import { isWeb } from 'APP/Helpers/checkPlatform'
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import { getImage, patchPractitionerProfileData } from 'APP/Lib/PractitionerHelpers'
import { Colors } from 'APP/Themes'
import Alert from 'APP/Converse/Alert'

import Styles from './style'
import moment from 'moment/moment'

const Time = ({ startTime, endTime, child }) => {
  return (
    <View style={Styles.timeContainer}>
      <MaterialCommunityIcon name="clock-outline" size={14} color={Colors.accent} />
      <Typography variant="bodySmallBold" style={Styles.time}>
        {startTime.format('h[:]mm')} - {endTime.format('h[:]mm A z')}
      </Typography>
      {child && (
        <Typography variant="caption" style={Styles.child}>
          {child.givenName}
        </Typography>
      )}
    </View>
  )
}

const Practitioner = ({ practitioner }) => {
  const navigation = useNavigation()
  const profile = useSelector((state) => state.patient.profile)

  const handlePractitionerProfilePress = (id) => {
    if (!id) return
    navigation.navigate('practitionerProfile', { userAppId: id, profileId: profile.id })
  }

  return (
    <View style={Styles.practitionerContainer}>
      <DialogueTouchableOpacity
        analyticsName={'upcomingAppointmentCard.practitionerBio'}
        onPress={() => handlePractitionerProfilePress(practitioner.id)}
        testID={'PRACTITIONER_AVATAR'}
      >
        {getImage(practitioner, Styles.practitionerAvatar)}
      </DialogueTouchableOpacity>
      <View style={Styles.practitionerInnerContainer}>
        <Typography variant="h4">{practitioner.full_name}</Typography>
        {practitioner.job_title && (
          <Typography variant="bodySmall">{practitioner.job_title}</Typography>
        )}
      </View>
    </View>
  )
}

const ReasonForConsult = ({ issueTypeId }) => {
  // Fallback to nothing if no issue type and don't render
  const issue = I18n.t(`health-issue-types:${issueTypeId}`, '')
  return (
    issue.length > 0 && (
      <View style={Styles.reasonContainer}>
        <Typography variant="bodyNormalBold">
          {I18n.t('Appointments.reasonForConsult')}
          <Typography variant="bodyNormal">{issue}</Typography>
        </Typography>
      </View>
    )
  )
}

const CancelAppointmentBottomSheet = ({
  isVisible,
  onCloseModal,
  onConfirmCancelAppointment,
  isLoading,
}) => {
  const descriptions = I18n.t('Appointments.cancelAppointmentBottomSheet.description', {
    returnObjects: true,
  })

  return (
    <BottomSheetModal
      isVisible={isVisible}
      title={I18n.t('Appointments.cancelAppointmentBottomSheet.title')}
      subtitle={I18n.t('Appointments.cancelAppointmentBottomSheet.subtitle')}
      onClose={onCloseModal}
      testID={'cancelAppointmentBottomSheet'}
      style={Styles.bottomModal}
    >
      <View style={Styles.bottomModalContainer}>
        <View style={Styles.bottomModalDescription}>
          <View style={Styles.descriptionTitleRow}>
            <MaterialIcon
              name="error-outline"
              size={16}
              color={Colors.text}
              style={Styles.descriptionIcon}
            />
            <Typography variant="bodyNormalBold" style={Styles.descriptionTitle}>
              {descriptions[0]}
            </Typography>
          </View>
          <Typography variant="bodyNormal" style={Styles.description}>
            {descriptions[1]}
          </Typography>
        </View>
        <Button
          onPress={onConfirmCancelAppointment}
          variant="tertiaryAlert"
          title={I18n.t('Appointments.cancelAppointmentBottomSheet.cta')}
          analyticsName="Yes, cancel appointment"
          widthVariant="full"
          testID="CANCEL_APPT_BOTTOM_SHEET_CANCEL_BTN"
          textColor="error"
          isLoading={isLoading}
        />
      </View>
    </BottomSheetModal>
  )
}

const Buttons = ({
  appointment,
  issueTypeId,
  onCancelAppointment,
  confirmCancelAppointment,
  isCancelRequestRunning,
}) => {
  const dispatch = useDispatch()
  const isRescheduleRequestRunning = useSelector(
    (state) => state.history.rescheduleAppointmentRunning
  )
  const navigation = useNavigation()

  const handleAddToCalPress = () => {
    const title = I18n.t('Appointments.calendarTitle', {
      issueType: I18n.t([`health-issue-types:${issueTypeId}`, 'Appointments.defaultIssueType']),
    })
    const description = I18n.t('Appointments.calendarDescription')
    const startDate = Moment.utc(appointment.start_at)
    const endDate = Moment.utc(appointment.end_at)

    const onError = () => {
      Alert.alert(I18n.t('CannotAddToCalendar.title'), I18n.t('CannotAddToCalendar.body'), [
        { text: I18n.t('CannotAddToCalendar.ok') },
      ])
    }

    if (isWeb()) {
      createIcsFile(title, description, startDate, endDate)
        .then((result) => Linking.openURL(result))
        .catch(onError)
    } else {
      const eventConfig = {
        title,
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        endDate: endDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        notes: description,
      }

      AddCalendarEvent.presentEventCreatingDialog(eventConfig).catch(onError)
    }
  }

  const alertMessage = () => {
    Alert.alert(I18n.t('Appointments.alert.title'), I18n.t('Appointments.alert.subTitle'), [
      {
        text: I18n.t('Appointments.alert.cta'),
        onPress: () => {
          dispatch(PatientHistoryActions.refreshAppointmentsRequest())
          navigation.goBack()
        },
      },
    ])
  }

  const handleReschedulePress = () => {
    const isModifiable = moment().isBefore(appointment.end_at)
    if (isModifiable) {
      dispatch(PatientHistoryActions.rescheduleAppointmentRequest(appointment))
    } else {
      alertMessage()
    }
  }

  const handleCancelPress = () => {
    const isModifiable = moment().isBefore(appointment.end_at)
    if (isModifiable) {
      dispatch(PatientHistoryActions.cancelAppointmentRequest(appointment))
    } else {
      alertMessage()
    }
  }

  return (
    <View style={Styles.buttonContainer}>
      <PrimaryButton
        style={Styles.button}
        title={I18n.t('Appointments.addToCalendar')}
        analyticsName="upcomingAppointmentCard.addToCalendar"
        onPress={handleAddToCalPress}
        testID="ADD_APPT_BTN"
      />
      {appointment.episode_id && (
        <>
          <SecondaryButton
            style={Styles.button}
            title={I18n.t('Appointments.reschedule')}
            analyticsName="upcomingAppointmentCard.reschedule"
            onPress={handleReschedulePress}
            showActivityIndicator={isRescheduleRequestRunning}
            testID="RESCHEDULE_APPT_BTN"
          />
          <Button
            variant="tertiaryAlert"
            widthVariant="full"
            textColor="error"
            title={I18n.t('Appointments.cancel')}
            analyticsName="upcomingAppointmentCard.cancel"
            onPress={confirmCancelAppointment ? onCancelAppointment : handleCancelPress}
            isLoading={isCancelRequestRunning}
            testID="CANCEL_APPT_BTN"
          />
        </>
      )}
    </View>
  )
}

// TODO add support for past appointments DIA-48643
const AppointmentCard = ({ style, appointment, disabled = false, showButtons = false }) => {
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const { confirmCancelAppointment } = useSelector((state) => state.features)
  const isCancelRequestRunning = useSelector((state) => state.history.cancelAppointmentRunning)

  const episode = useSelector((state) => state.history.episodes[appointment.episode_id])
  const practitioners = useSelector((state) => state.history.practitioners)

  const practitioner = useMemo(() => {
    return patchPractitionerProfileData(appointment?.practitioner, practitioners)
  }, [appointment.practitioner, practitioners])

  const startTime = Moment(appointment.start_at)
  const endTime = Moment.tz(appointment.end_at, Moment.tz.guess(true))
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false)

  const WrapperComponent = disabled ? View : DialogueTouchableOpacity

  const onCloseModal = () => {
    Analytics.trackEvent('button_click', {
      button_value: 'Close cancel appointment',
    })
    setIsBottomSheetVisible(false)
  }

  const onCancelAppointment = () => {
    setIsBottomSheetVisible(true)
  }

  const onConfirmCancelAppointment = () => {
    setIsBottomSheetVisible(false)
    dispatch(PatientHistoryActions.cancelAppointmentRequest(appointment))
  }

  return (
    <>
      <WrapperComponent
        style={[Styles.cardContainer, style]}
        onPress={
          disabled ? undefined : () => navigation.navigate('upcomingAppointment', { appointment })
        }
        analyticsName={'upcomingAppointmentCard'}
        testID={'UPCOMING_APPOINTMENT_CARD'}
      >
        <View style={Styles.appointmentContainer}>
          <View style={Styles.dateContainer}>
            <Typography variant="h1" style={Styles.dateDay}>
              {startTime.format('D')}
              <Typography variant="bodyLarge">
                {'\n'}
                {startTime.format('MMM')}
              </Typography>
            </Typography>
          </View>
          <View style={Styles.appointmentDetailContainer}>
            <Time startTime={startTime} endTime={endTime} child={appointment.child} />
            <Practitioner practitioner={practitioner} />
            {episode?.healthIssueTypeId && (
              <ReasonForConsult issueTypeId={episode?.healthIssueTypeId} />
            )}
            {showButtons && (
              <Buttons
                appointment={appointment}
                issueTypeId={episode?.healthIssueTypeId}
                onCancelAppointment={onCancelAppointment}
                confirmCancelAppointment={confirmCancelAppointment}
                isCancelRequestRunning={isCancelRequestRunning}
              />
            )}
          </View>
        </View>
      </WrapperComponent>
      {confirmCancelAppointment && (
        <CancelAppointmentBottomSheet
          isVisible={isBottomSheetVisible}
          onCloseModal={onCloseModal}
          onCancelAppointment={onCancelAppointment}
          onConfirmCancelAppointment={onConfirmCancelAppointment}
          isLoading={isCancelRequestRunning}
        />
      )}
    </>
  )
}

export default AppointmentCard
