import React from 'react'
import { ActivityIndicator, TouchableOpacity, Text, View, Dimensions } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialIcons'

import { ApplicationStyles, Colors, Fonts, Metrics } from 'APP/Themes'
import Analytics from 'APP/Services/Analytics'

const widthAwareFontStyle = (buttonText) => {
  const isTextLong = buttonText.length > 30
  const isSmallWindow = Dimensions.get('window').width < 360
  const smallButton = Object.assign({}, Fonts.style.button, { fontSize: Fonts.size.small })
  return isTextLong && isSmallWindow ? smallButton : Fonts.style.button
}

// Base Button
class BaseButton extends React.Component {
  getText() {
    const buttonText =
      this.props.title || (this.props.children && this.props.children.toString()) || ''
    return buttonText
  }

  onWrappedKeyPress = (event) => {
    if (this.props.onPress) {
      const name = this.getText()
      const value = this.props.analyticsName
      Analytics.trackEvent('button_click', { button_text: name, button_value: value })
      this.props.onPress(event)
    }
  }

  render() {
    const style = this.props.disabled
      ? this.props.disabledContainerStyle
      : this.props.containerStyle
    const textStyle = this.props.disabled ? this.props.disabledTextStyle : this.props.textStyle
    const buttonText = this.getText()
    const underlayColor = this.props.underlayColor

    const iconSize = this.props.slim ? Metrics.icons.small : Metrics.icons.medium
    const iconSpacing = this.props.slim ? Metrics.baseMargin / 2 : Metrics.baseMargin
    const iconStyle = {
      paddingRight: this.props.leftIcon ? iconSpacing : 0,
    }

    const testId =
      this.props.testID ||
      (buttonText
        ? `${buttonText
            .toUpperCase()
            .trim()
            .replace(/\s+/g, ' ')
            .replace(/[^A-Z0-9 ]/g, '')
            .replace(/ /g, '_')}_BTN`
        : 'BASE_BTN')

    return (
      <TouchableOpacity
        style={[
          {
            marginHorizontal: Metrics.baseMargin,
            paddingHorizontal: Metrics.baseMargin,
            borderRadius: Metrics.buttonRadius,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          },
          style,
          this.props.slim && {
            height: null,
            paddingVertical: Metrics.baseMargin / 2,
          },
          this.props.style,
        ]}
        underlayColor={underlayColor}
        disabled={this.props.disabled}
        onPress={this.onWrappedKeyPress}
        activeOpacity={0.5}
        testID={testId}
      >
        <View>
          {this.props.showActivityIndicator ? (
            <ActivityIndicator color={this.props.activityIndicatorColor ?? Colors.text} />
          ) : (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {this.props.leftIcon && (
                <Icon
                  size={iconSize}
                  color={Colors.accent}
                  name={this.props.leftIcon}
                  style={iconStyle}
                />
              )}
              <Text
                style={[
                  textStyle,
                  this.props.disableWidthAwareFontStyle ? {} : widthAwareFontStyle(buttonText),
                ]}
              >
                {buttonText}
              </Text>
            </View>
          )}
        </View>
      </TouchableOpacity>
    )
  }
}

// Button Classes
export class PrimaryButton extends React.Component {
  render() {
    return (
      <BaseButton
        {...this.props}
        containerStyle={ApplicationStyles.screen.primaryButton}
        disabledContainerStyle={ApplicationStyles.screen.primaryButtonDisabled}
        textStyle={ApplicationStyles.screen.primaryButtonText}
        disabledTextStyle={ApplicationStyles.screen.primaryButtonDisabledText}
      />
    )
  }
}

export class PrimaryContrastButton extends React.Component {
  render() {
    return (
      <BaseButton
        {...this.props}
        containerStyle={ApplicationStyles.screen.primaryButtonWhite}
        disabledContainerStyle={ApplicationStyles.screen.primaryContrastButtonDisabled}
        textStyle={ApplicationStyles.screen.primaryButtonWhiteText}
        disabledTextStyle={[ApplicationStyles.screen.primaryButtonWhiteText, { opacity: 0.5 }]}
      />
    )
  }
}

export class PrimaryAlternativeButton extends React.Component {
  render() {
    return (
      <BaseButton
        {...this.props}
        containerStyle={ApplicationStyles.screen.primaryButtonAlternative}
        disabledContainerStyle={ApplicationStyles.screen.primaryContrastButtonDisabled}
        textStyle={ApplicationStyles.screen.primaryButtonAlternativeText}
        disabledTextStyle={[ApplicationStyles.screen.primaryButtonWhiteText, { opacity: 0.5 }]}
      />
    )
  }
}

export class SecondaryButton extends React.Component {
  render() {
    return (
      <BaseButton
        {...this.props}
        containerStyle={ApplicationStyles.screen.secondaryButton}
        disabledContainerStyle={ApplicationStyles.screen.secondaryButtonDisabled}
        textStyle={ApplicationStyles.screen.secondaryButtonText}
        disabledTextStyle={[ApplicationStyles.screen.secondaryButtonDisabledText]}
      />
    )
  }
}

export class SecondaryContrastButton extends React.Component {
  render() {
    return (
      <BaseButton
        {...this.props}
        containerStyle={ApplicationStyles.screen.secondaryButtonWhite}
        disabledContainerStyle={ApplicationStyles.screen.secondaryContrastButtonDisabled}
        textStyle={ApplicationStyles.screen.secondaryButtonWhiteText}
        disabledTextStyle={[ApplicationStyles.screen.secondaryButtonWhiteText, { opacity: 0.5 }]}
      />
    )
  }
}

export class SecondaryAlternativeButton extends React.Component {
  render() {
    return (
      <BaseButton
        {...this.props}
        containerStyle={ApplicationStyles.screen.secondaryButtonAlternative}
        disabledContainerStyle={ApplicationStyles.screen.secondaryContrastButtonDisabled}
        textStyle={ApplicationStyles.screen.secondaryButtonAlternativeText}
        disabledTextStyle={[ApplicationStyles.screen.secondaryButtonWhiteText, { opacity: 0.5 }]}
      />
    )
  }
}

export class ShadowHoverButton extends React.Component {
  render() {
    return (
      <BaseButton
        {...this.props}
        containerStyle={ApplicationStyles.screen.shadowHoverButton}
        disabledContainerStyle={ApplicationStyles.screen.secondaryContrastButtonDisabled}
        textStyle={ApplicationStyles.screen.shadowHoverButtonText}
        disabledTextStyle={[ApplicationStyles.screen.secondaryButtonWhiteText, { opacity: 0.5 }]}
      />
    )
  }
}

export class PrimaryTextButton extends React.Component {
  render() {
    return (
      <BaseButton
        containerStyle={ApplicationStyles.screen.primaryTextButton}
        disabledContainerStyle={ApplicationStyles.screen.primaryTextButtonDisabled}
        textStyle={ApplicationStyles.screen.primaryTextButtonText}
        disabledTextStyle={[ApplicationStyles.screen.primaryTextButtonText, { opacity: 0.5 }]}
        {...this.props}
      />
    )
  }
}

export class TertiaryButton extends React.Component {
  render() {
    return (
      <BaseButton
        {...this.props}
        underlayColor={Colors.buttonSelected}
        containerStyle={ApplicationStyles.screen.tertiaryButton}
        disabledContainerStyle={ApplicationStyles.screen.tertiaryButton}
        textStyle={ApplicationStyles.screen.tertiaryButtonText}
        disabledTextStyle={ApplicationStyles.screen.tertiaryButtonDisabledText}
      />
    )
  }
}

export class SecondaryActiveButton extends React.Component {
  render() {
    return (
      <BaseButton
        {...this.props}
        containerStyle={ApplicationStyles.screen.secondaryActiveButton}
        disabledContainerStyle={ApplicationStyles.screen.secondaryActiveButtonDisabled}
        textStyle={ApplicationStyles.screen.secondaryActiveButtonText}
        disabledTextStyle={ApplicationStyles.screen.secondaryActiveButtonDisabledText}
        activityIndicatorColor={Colors.darkText}
      />
    )
  }
}

export const LinkButton = (props) => {
  return (
    <BaseButton
      {...props}
      disableWidthAwareFontStyle={true}
      underlayColor={Colors.buttonSelected}
      containerStyle={ApplicationStyles.screen.linkButton}
      disabledContainerStyle={ApplicationStyles.screen.linkButton}
      textStyle={ApplicationStyles.screen.linkButtonText}
      disabledTextStyle={ApplicationStyles.screen.linkButtonDisabledText}
    />
  )
}

export default {
  PrimaryButton,
  PrimaryContrastButton,
  PrimaryAlternativeButton,
  SecondaryButton,
  SecondaryContrastButton,
  SecondaryAlternativeButton,
  ShadowHoverButton,
  PrimaryTextButton,
  TertiaryButton,
  SecondaryActiveButton,
  LinkButton,
}
