import React from 'react'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import { useTheme } from 'styled-components/native'
import { snakeCase } from 'lodash'

const CONTENT_TYPE_ICON = {
  video: {
    icon: MaterialIcon,
    key: 'ondemand-video',
  },
  article: {
    icon: MaterialIcon,
    key: 'import-contacts',
  },
  audio: {
    icon: MaterialCommunityIcon,
    key: 'headphones',
  },
}

const ContentIcon = ({ type = 'article' }: { type?: 'video' | 'audio' | 'article' } = {}) => {
  const theme = useTheme()
  const Icon = CONTENT_TYPE_ICON[type].icon
  const key = CONTENT_TYPE_ICON[type].key

  return (
    <Icon
      testID={`ICON_${snakeCase(key).toUpperCase()}`}
      name={key}
      size={theme.metrics.icon.tiny}
      color={theme.colors.accent}
    />
  )
}

export default ContentIcon
